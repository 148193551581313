import { Route } from '@angular/router';
import { authGuard, authRoutes } from '@af-web/auth';
import { pagesRoutes } from './pages/pages.routes';

export const appRoutes: Route[] = [
  { path: 'auth', children: authRoutes },
  {
    path: '',
    canActivate: [
      authGuard
    ],
    loadComponent: () => import('@af-web/shared').then((c) => c.MainComponent),
    children: pagesRoutes,
  },
  {
    path: '**',
    redirectTo: ''
  }
];
