import { Route } from '@angular/router';

export const pagesRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'contratos',
    pathMatch: 'full'
  },
  {
    path: 'centros-custo',
    children: [
      {
        path: '',
        loadComponent: () => import('./centro-custo/centro-custo.component').then(c => c.CentroCustoComponent),
        pathMatch: 'full'
      },
      {
        path: ':id',
        loadComponent: () => import('./centro-custo/centro-custo-form/centro-custo-form.component').then(c => c.CentroCustoFormComponent)
      }
    ]
  },
  {
    path: 'resume',
    loadComponent: () => import('./general-resume/afw-general-resume.component').then(c => c.AfwGeneralResumeComponent)
  },
  {
    path: 'contratos',
    children: [
      {
        path: '',
        loadComponent: () => import('./contrato/contrato.component').then(c => c.ContratoComponent),
        pathMatch: 'full'
      },
      {
        path: 'new',
        loadComponent: () => import('./contrato/contrato-form/contrato-form.component').then(c => c.ContratoFormComponent)
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadComponent: () => import('./contrato/contrato-form/contrato-form.component').then(c => c.ContratoFormComponent),
          },
          {
            path: 'resume',
            loadComponent: () => import('./contrato/resume/resume.component').then(c => c.ResumeComponent)
          }
        ]
      }
    ]
  },
  {
    path: 'empresas',
    children: [
      {
        path: '',
        loadComponent: () => import('./empresa/empresa.component').then(c => c.EmpresaComponent),
        pathMatch: 'full'
      },
      {
        path: 'new',
        loadComponent: () => import('./empresa/empresa-form/empresa-form.component').then(c => c.EmpresaFormComponent)
      },
      {
        path: ':id',
        loadComponent: () => import('./empresa/empresa-form/empresa-form.component').then(c => c.EmpresaFormComponent)
      }
    ]
  },
  {
    path: 'fornecedores',
    children: [
      {
        path: '',
        loadComponent: () => import('./fornecedor/fornecedor.component').then(c => c.FornecedorComponent),
        pathMatch: 'full'
      },
      {
        path: 'new',
        loadComponent: () => import('./fornecedor/fornecedor-form/fornecedor-form.component').then(c => c.FornecedorFormComponent)
      },
      {
        path: ':id',
        loadComponent: () => import('./fornecedor/fornecedor-form/fornecedor-form.component').then(c => c.FornecedorFormComponent)
      }
    ]
  }
];