import { Injectable } from '@angular/core';
import { Menu, MenuService } from '@af-web/shared';

@Injectable({
  providedIn: 'root'
})
export class FvMenuService extends MenuService {

  override get menus(): Array<Menu> {
    return [
      {
        id: 'conciliacao',
        routerLink: '/resume',
        label: 'Conciliação',
        menuIcon: 'lucideHandshake'
      },
      {
        id: 'fornecedores',
        routerLink: '/fornecedores',
        label: 'Fornecedores',
        menuIcon: 'lucideTruck'
      },
      {
        id: 'empresas',
        routerLink: '/empresas',
        label: 'Empresas',
        menuIcon: 'lucideBuilding2'
      },
      {
        id: 'contratos',
        routerLink: '/contratos',
        label: 'Contratos',
        menuIcon: 'lucideFileText'
      },
    ];
  }
}
