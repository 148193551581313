import { Component, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { SwitchThemeService } from '@af-web/shared';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  imports: [RouterModule, MatProgressSpinner],
  selector: 'fv-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  switchThemeService = inject(SwitchThemeService);
  private router: Router = inject(Router);
  protected isLoading = true;

  constructor() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      } else if (event instanceof NavigationEnd) {
        this.isLoading = false;
      }
    });
  }
  ngOnInit() {
    this.switchThemeService.switchTheme();
  }
}
