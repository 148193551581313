import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '@af-web/auth';
import { FvAuthService } from '../services/auth/fv-auth.service';
import { FvConfigService } from '../services/config/fv-config.service';

export const fvAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const _configService: FvConfigService = inject(FvConfigService);
  const _tokenService: TokenService = inject(TokenService);
  const _authService: FvAuthService = inject(FvAuthService);
  const _toastrService: ToastrService = inject(ToastrService);
  const token = _tokenService.token;

  const WHITE_LIST = [
    `${_configService.baseUrl}/auth/login`,
    `${_configService.baseUrl}/auth/configure-psw/.+`
  ];


  const handleHttpError = (httpResponseError: HttpErrorResponse) => {
    switch (httpResponseError.status) {
      case HttpStatusCode.Forbidden:
        _authService.logout();
        break;
      case 0:
        _toastrService.error('Could not access the server! Please contact support');
        break;
    }
  };

  if (!token && WHITE_LIST.some(url => new RegExp(url).test(req.url)))
    return next(req).pipe(
      catchError(
        (httpResponseError: HttpErrorResponse) => {
          handleHttpError(httpResponseError);
          return next(req);
        }
      )
    );

  const refreshToken = _tokenService.refreshToken;
  if (refreshToken && req.url.includes(`${_configService.baseUrl}/auth/refresh`)) {
    const newReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + refreshToken)
    });
    return next(newReq).pipe(
      catchError(
        (httpResponseError: HttpErrorResponse) => {
          handleHttpError(httpResponseError);
          return next(newReq);
        }
      )
    );
  }

  if (_tokenService.isTokenExpired()) {
    return _authService.refresh().pipe(
      switchMap(
        () => {
          return next(
            req.clone({
              headers: req.headers.set('Authorization', 'Bearer ' + _tokenService.refreshToken)
            })
          ).pipe(
            catchError(
              (httpResponseError: HttpErrorResponse) => {
                handleHttpError(httpResponseError);
                return next(req);
              }
            )
          );
        }
      )
    );
  }

  if (_tokenService.isTokenValid()) {
    const newReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token)
    });
    return next(newReq).pipe(
      catchError(
        (httpResponseError: HttpErrorResponse) => {
          handleHttpError(httpResponseError);
          return next(newReq);
        }
      )
    );
  }

  return next(req).pipe(
    catchError(
      (httpResponseError: HttpErrorResponse) => {
        handleHttpError(httpResponseError);
        return next(req);
      }
    )
  );
};
