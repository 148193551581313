import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonResponse } from '@af-web/shared';
import { catchError, map, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthModel, AuthService, LoginModel, SessionService, TokenService, UserModel } from '@af-web/auth';
import { FvConfigService } from '../config/fv-config.service';
import { PasswordModel } from '../../../../../../../libs/auth/src/lib/models/password/password.model';

@Injectable({
  providedIn: 'root'
})
export class FvAuthService extends AuthService {

  private httpClient: HttpClient = inject(HttpClient);
  private configService: FvConfigService = inject(FvConfigService);
  private tokenService: TokenService = inject(TokenService);
  private sessionService: SessionService = inject(SessionService);
  private router: Router = inject(Router);

  login(login: LoginModel) {
    return this.httpClient.post<JsonResponse<AuthModel>>(
      `${this.configService.baseUrl}/auth/login`, login
    ).pipe(
      map(
        (response) => response.result
      ),
      map(
        (auth: AuthModel) => {
          this.populateSession(auth);
          return true;
        }
      ),
      catchError(
        httpResponseError => {
          throw httpResponseError;
        }
      )
    );
  }

  logout() {
    this.tokenService.removeTokens();
    this.router.navigate(['auth']).then();
  }

  refresh() {
    return this.httpClient.get<JsonResponse<AuthModel>>(`${this.configService.baseUrl}/auth/refresh`).pipe(
      map((response: JsonResponse<AuthModel>) => {
        this.populateSession(response.result);
      })
    );
  }

  protected populateSession(auth: AuthModel) {
    this.tokenService.token = auth.accessToken;
    this.tokenService.refreshToken = auth.refreshToken;
    this.sessionService.userState = {
      id: auth.idUser,
      email: auth.name
    } as UserModel;
    // this.permissionService.permissions = auth.permissions;
  }

  configurePassword(passwordModel: PasswordModel, token: string): Observable<JsonResponse<unknown>> {
    return this.httpClient.put<JsonResponse<unknown>>(`${this.configService.baseUrl}/auth/configure-psw/${token}`, passwordModel);
  }

}
